import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import architectureImage from "../images/architecture.png";

const logoStyle = {
  // width: '100px',
  height: '700px',
  margin: '0 32px',
  // opacity: 1,
};

export default function Architecture() {

  return (
    <Box id="logoCollection" sx={{ py: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Typography component="h2" variant="h4" color="text.primary">
        Product Architecture
      </Typography>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
      >
        HelloPay ensures the credibility of the payment process through a decentralized technical architecture.
      </Typography>
      <Box sx={{ mt: 6, opacity: 0.9, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <img
          src={architectureImage}
          alt="architecture"
          style={logoStyle}
        />

      </Box>
    </Box>
  );
}
