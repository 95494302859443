import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import exchangeImage from "../images/exchange.png";
import heroImage from "../images/hero.png";

import { visuallyHidden } from '@mui/utils';
import ReactFloaterJs from "react-floaterjs";

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        height: '100vh',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)'
            : 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          pt: { xs: 5, sm: 10 },
          pb: { xs: 8, sm: 12 },
          height: '100%'
        }}
      >
        <Stack
          spacing={2}
          alignItems="left"
          useFlexGap
          sx={{ width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
              pb: '50px'
            }}
          >
            {/*The&nbsp;first&nbsp;web3&nbsp;social&nbsp;payment&nbsp;platform*/}
            Crypto-native social graph payment Platform
            {/*<Typography*/}
            {/*  component="span"*/}
            {/*  variant="h1"*/}
            {/*  sx={{*/}
            {/*    fontSize: 'inherit',*/}
            {/*    color: (theme) =>*/}
            {/*      theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  products*/}
            {/*</Typography>*/}
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ width: { sm: '100%', md: '80%' } }}
          >

            HelloPay will focus on social payment scenarios and launch a series of modular
            functions to meet users’ cryptocurrency social payment needs, such as tips, crowdfunding,
            donations, draws, contests, paid subscriptions and etc.

          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <Button variant="contained" color="primary" href="https://docs.hellopay.xyz/">
              Learn More
            </Button>
            <Button variant="contained" color="secondary" href="https://warpcast.com/hellopay/0x6c8b8260">
              Go To DAPP
            </Button>
          </Stack>
          {/*<Typography variant="caption" textAlign="left">*/}
          {/*  By clicking &quot;Start now&quot; you agree to our&nbsp;*/}
          {/*  <Link href="#" color="primary">*/}
          {/*    Terms & Conditions*/}
          {/*  </Link>*/}
          {/*  .*/}
          {/*</Typography>*/}
        </Stack>
        <ReactFloaterJs>
          <img data-aos="fade-up" src={heroImage}/>
        </ReactFloaterJs>
        {/*<Box*/}
        {/*  id="image"*/}
        {/*  sx={(theme) => ({*/}
        {/*    mt: { xs: 8, sm: 10 },*/}
        {/*    alignSelf: 'center',*/}
        {/*    height: { xs: 200, sm: 700 },*/}
        {/*    width: '100%',*/}
        {/*    backgroundImage:*/}
        {/*      theme.palette.mode === 'light'*/}
        {/*        ? 'url("/static/images/templates/templates-images/hero-light.png")'*/}
        {/*        : 'url("/static/images/templates/templates-images/hero-dark.png")',*/}
        {/*    backgroundSize: 'cover',*/}
        {/*    borderRadius: '12px',*/}
        {/*    outline: '1px solid',*/}
        {/*    outlineColor:*/}
        {/*      theme.palette.mode === 'light'*/}
        {/*        ? 'hsla(220, 25%, 80%, 0.5)'*/}
        {/*        : 'hsla(210, 100%, 80%, 0.1)',*/}
        {/*    boxShadow:*/}
        {/*      theme.palette.mode === 'light'*/}
        {/*        ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'*/}
        {/*        : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',*/}
        {/*  })}*/}
        {/*/>*/}
      </Container>
    </Box>
  );
}
