import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import PeopleIcon from '@mui/icons-material/People';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/material/Link";

const items = [
  {
    icon: <PeopleIcon />,
    title: 'Decentralized',
    description:
      'The logic layer and fund settlement layer of all HelloPay functional modules are implemented through smart contracts on the chain, ensuring the openness and transparency of the entire process and increasing credibility.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Social payment fitness',
    description:
      'HelloPay will focus on social payment scenarios, fully explore user needs, and launch more interesting social payment functions.',
  },
  {
    icon: <CardGiftcardIcon />,
    title: 'Totally free to use',
    description:
      'Using HelloPay’s functionality is completely free, you only need to pay the Gas fee for sending transactions on the blockchain.',
  },
  // {
  //   icon: <AutoFixHighRoundedIcon />,
  //   title: 'Cross Chain',
  //   description:
  //     '',
  // },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'hsl(220, 30%, 2%)',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Product features
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Through on-chain and off-chain technical solutions, HelloPay has the following characteristics:
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'hsla(220, 25%, 25%, .3)',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                  boxShadow: 'none',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                  {/*<Link*/}
                  {/*  color="primary"*/}
                  {/*  variant="body2"*/}
                  {/*  fontWeight="bold"*/}
                  {/*  sx={{*/}
                  {/*    display: 'inline-flex',*/}
                  {/*    alignItems: 'center',*/}
                  {/*    pt: 2,*/}
                  {/*    '& > svg': { transition: '0.2s' },*/}
                  {/*    '&:hover > svg': { transform: 'translateX(2px)' },*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <span>Learn more</span>*/}
                  {/*  <ChevronRightRoundedIcon*/}
                  {/*    fontSize="small"*/}
                  {/*    sx={{ mt: '1px', ml: '2px' }}*/}
                  {/*  />*/}
                  {/*</Link>*/}
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
